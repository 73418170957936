import React from 'react';
import Layout from '../../components/Layout/Layout';
import { graphql } from 'gatsby'
import ComponentParser from '../../utils/ComponentParser';
import Seo from "../../components/Seo"
import { StaticImage } from 'gatsby-plugin-image';

const page = ( { data: { page } } ) => {

	const containClass = page?.pageMeta?.fullWidthPage ? 'full-width-page' : "max-w-2xl mx-auto mt-16 md:mt-24 lg:mt-36 pb-16 px-4 md:px-0 relative z-10"
	const seoTitle     = page.seo.title ? page.seo.title : page.title;

	return (
		<Layout>
			<Seo
				title={seoTitle}
				canonical={page.seo?.canonical}
				uri={page.uri}
				socialImage={page.featuredImage?.node}
				description={page.seo?.metaDesc}
			/>

			{ ! page?.pageMeta?.fullWidthPage && 
				<StaticImage src='../../assets/images/page-bg.png' className="page-header-bg absolute top-0 left-0 right-0 z-0" alt="Page Header Background" />
			}

			<div className={containClass}>
			{page.blocks &&
      		page.blocks.map((block, i) => {
				return (
					<div key={i}>
						<ComponentParser
							block={block}
							page={page}
						/>
					</div>
				)
			})}
			</div>

		</Layout>
	)
}

export const pageQuery = graphql`
	query PageById(
		$id: String!
	) {
		page: wpPage( id: { eq: $id } ) {
			... PageContent
		}
	}
`

export default page